// extracted by mini-css-extract-plugin
export var bottom = "verification-page-module--bottom--0ec6a";
export var buttons = "verification-page-module--buttons--2bf1c";
export var center = "verification-page-module--center--b6f62";
export var content = "verification-page-module--content--07311";
export var float = "verification-page-module--float--17af6";
export var modal = "verification-page-module--modal--21b26";
export var redes = "verification-page-module--redes--9de0b";
export var santaleza = "verification-page-module--santaleza--25dbc";
export var top = "verification-page-module--top--59bb3";
export var verificationPage = "verification-page-module--verificationPage--38ee0";